.c-button,
button {

  margin: 10px 0;
  padding: 20px;
  display: block;
  width: 100%;
  font-family: $sans-serif;
  background-color: map-get($brand, "button");
  border-radius: 30px;
  text-align: center;
  text-decoration: none;
  color: white;
  border: 0;
  cursor: pointer;
  @extend .t-button;
  transition: $trans-speed $trans-ease;

  @include grid-media($grid-small) {

    padding: 20px 30px;
    margin: 0 10px;
    width: auto;

  }

  &:hover,
  &:focus {

    opacity: 0.3;

  }

  &:disabled,
  button[disabled]{

    opacity: 0.3;
    pointer-events: none;

  }

  &__social {

    display: block;
    width: 60px;
    width: 60px;

    svg {

      fill: map-get($brand, "accent");
      width: 100%;
      height: 100%;

    }

  }

}

.c-buttons {

  @include grid-media($grid-small) {

    display: flex;

  }

  .c-button:first-child {

    margin-left: 0;

  }

}
