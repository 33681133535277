.c-garden {

  color: white;
  margin-top: 100px;
  padding: 0 20px;

  @include grid-media($grid-small) {

    padding: 0 40px;

  }

  &__inner {

    margin: auto;
    max-width: map-get($sizes, "max-width-inner");

    @include grid-media($grid-medium) {

      display: flex;
      justify-content: flex-end;

    }

  }

  h2,
  &__share h3 {

    color: map-get($brand, 'accent');

  }

  &__share h3 {

    @extend .t-garden__share;

  }

  h2 {

    @extend .t-garden__title;
    margin-bottom: 50px;

    @include grid-media($grid-large) {

      width: 671px;
      max-width: 671px;
      min-width: 655px;

    }

  }

  h3 {

    &:first-child {

      margin: 0 0 10px 0;

    }

  }

  &__share {

    width: 100%;
    background-color: white;
    padding: 20px;

    @include grid-media($grid-small) {

      min-width: 570px;
      padding: 40px;
      align-self: flex-start;

    }

    @include grid-media($grid-medium) {

      margin-left: 40px;

    }

    @include grid-media($grid-large) {

      margin-left: 90px;

    }

    p {

      color: black;
      margin-bottom: 20px;

      @include grid-media($grid-small) {

        margin-bottom: 40px;

      }

      @include grid-media($grid-medium) {

        line-height: 30px;

      }

      &:first-child {

        margin-top: 10px;

      }

    }

  }

  &__card {

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 0 40px 0;
    min-width: 50%;

    @include grid-media($grid-large) {

      min-width: 56%;
      padding: 50px 70px;

    }

    .c-card {

      width: 100%;
      max-width: 320px;
      margin-bottom: 0;

      @include grid-media($grid-medium) {

        max-width: 390px;

      }

    }
  }

  .c-buttons {

    margin-top: 30px;

  }

  .c-button {

    width: 100%;

    @include grid-media($grid-small) {

      width: calc(50% - 10px);

    }

    @include grid-media($grid-medium) {

      width: auto;

    }

  }

}
