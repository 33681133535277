%a11y {

  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

}

%clearfix {

  &::before {

    content: "";
    display: block;
    clear: both;

  }

}

%text-style {

  font-family: $sans-serif;
  font-size: 18pt;
  line-height: 29pt;

}

%text-left {

  text-align: left;

}

%text-center {

  text-align: center;

}

%list-reset {

  list-style: none;
  margin: 0;
  padding: 0;

}
