.c-nav-social {

  width: 100%;
  display: none;
  max-width: 734px;
  margin: 0 auto;

  @include grid-media($grid-small) {

    display: block;

  }

  &__list {

    display: flex;
    justify-content: center;
    padding: 0;

  }

  li {

    list-style: none;overflow-y: auto;
    display: block;
    width: 60px;
    margin: 0 10px;

  }

  svg {

    margin: 0;
    width: 60px;
    height: 60px;
    fill: white;

  }

  hr {

    margin: 50px 0;

  }

}
