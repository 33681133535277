.c-footer {

  color: white;
  padding: 0 20px;
  background-color: map-get($brand, "footer");

  @include grid-media($grid-small) {

    padding: 0 40px;

  }

  &__inner {

    margin: auto;
    max-width: map-get($sizes, "max-width-inner");

  }

  &__top {

    margin: 50px 0 50px 0;

    @include grid-media($grid-medium) {

      margin: 100px 0 100px 0;

    }

  }

  &__bottom {

    margin: 0 0 40px 0;

    @include grid-media($grid-medium) {

      display: flex;
      justify-content: space-between;
      margin: 0 0 100px 0;

    }

    @include grid-media($grid-medium) {

      padding-right: 120px;

    }

  }

  &__left,
  &__right {

    width: 100%;
    margin: 50px 0 0 0;

    @include grid-media($grid-medium) {

      width: calc(50% - 20px);

    }

  }

  &__left {

    width: 100%;

    @include grid-media($grid-medium) {

      margin: 0 10px 0 0;

    }

    @include grid-media($grid-medium) {

      max-width: 505px;

    }

  }

  &__right {

    width: 100%;

    @include grid-media($grid-medium) {

      margin: 0 0 0 10px;

    }

    @include grid-media($grid-medium) {

      max-width: 572px;

    }

  }

  &__signature {

    text-align: center;
    color: white;
    margin: 0;
    @extend .t-footer__signature;
    padding: 15px 0 20px;

    @include grid-media($grid-small) {

      text-align: left;
      padding: 25px 0 30px;

    }

    a {

      color: white;

    }

  }

  h2 {

    max-width: 710px;
    @extend .t-footer__title;

  }

  h3 {

    color: white;

  }

  hr {

    margin-top: 0;

  }

  .c-buttons {

    margin: 30px 0 0 0;

  }

}
