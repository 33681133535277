::selection {

  background-color: map-get($brand, 'accent');
  color: white;

}

html {

  box-sizing: border-box;
  text-size-adjust: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: 1em;
  line-height: 1.5;
  min-height: 100%;
  font-family: $sans-serif;

  @media print {

    -webkit-print-color-adjust: exact; /*Chrome, Safari */
    color-adjust: exact; /*Firefox*/

  }

}

body {

  overflow-x: hidden;
  background-color: map-get($brand, "body");
  font-weight: 400;

}

hr {

  border: 0;
  border-top: 2px solid map-get($brand, "hr");
  opacity: 0.1;

}

*,
*:before,
*:after {

  box-sizing: inherit;

}

svg {

  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: auto;

}

img {

  border-radius: 0;

}

#app {

  position: relative;
  overflow-x: hidden;

}
