.c-bold {

  color: white;
  margin-top: 100px;
  padding: 0 20px;

  @include grid-media($grid-medium) {

    padding: 0 40px;

  }

  &__inner {

    display: flex;
    margin: auto;
    justify-content: flex-start;
    max-width: map-get($sizes, "max-width-inner");

  }

  &__container {

    width: 100%;
    color: black;

    @include grid-media($grid-small) {

      max-width: 932px;

    }

  }

  h2 {

    color: map-get($brand, "accent");
    @extend .t-bold;
    margin-bottom: 25px;

    @include grid-media($grid-small) {

      margin-bottom: 65px;
      max-width: 82%;

    }

    @include grid-media($grid-medium) {

      max-width: 100%;

    }

  }

  p {

    color: map-get($brand, "accent");
    max-width: 724px;
    @extend .t-bold__detail;
    margin-bottom: 30px;

    @include grid-media($grid-small) {

      margin-bottom: 40px;

    }

  }

}
