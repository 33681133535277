.c-detail {

  color: white;
  margin-top: 100px;
  padding: 0 20px;

  @include grid-media($grid-small) {

    padding: 0 40px;

  }

  &__inner {

    display: flex;
    margin: auto;
    justify-content: flex-end;
    max-width: map-get($sizes, "max-width-inner");

  }

  &__container {

    width: 100%;
    color: black;

    @include grid-media($grid-medium) {

      max-width: 58%;

    }

  }

  h3 {

    color: map-get($brand, "accent");

  }

  p {

    line-height: 30px;
    margin-bottom: 20px;

    @include grid-media($grid-small) {

      margin-bottom: 40px;

    }

  }

}
