%circle {
  height: 13px;
  width: 13px;
  border-radius: 13px;
  background: rgba(map-get($brand, 'accent'), 0.7);
  margin: 0 7px;
}

@keyframes opacityCycle {
  from {
      opacity: 0.3;
  }

  to {
      opacity: 1;
  }
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  span {
      @extend %circle;
      animation: opacityCycle 0.7s ease-in-out 0s infinite alternate;

      &:nth-child(2) {
          animation-delay: 0.4s;
      }

      &:nth-child(3) {
          animation-direction: alternate-reverse;
      }
  }
}
