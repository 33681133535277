.c-modal {

  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  padding: 20px;
  background-color: map-get($brand, 'modal-background');

  @include grid-media($grid-small) {

    padding: 40px;

  }

  &__inner {

    width: 100%;
    max-height: 100%;
    border-radius: 5px;
    margin: auto;
    background-color: map-get($brand, 'body');
    padding: 10px 20px 40px 20px;
    box-shadow: 0px 0px 20px 0px map-get($brand, 'drop-shadow');
    overflow-y: scroll;
    position: relative;

    @include grid-media($grid-small) {

      padding: 30px;

    }

    @include grid-media($grid-medium) {

      max-width: map-get($sizes, 'max-width-modal');

    }

    @include grid-media($grid-large) {

      padding-left: 20%;

    }

    @include grid-media($grid-x-large) {

      padding-left: 300px;

    }

    &:before {

      @include grid-media($grid-large) {

        content: "";
        position: absolute;
        pointer-events: none;
        width: calc(25% - 100px);
        height: 100%;
        background-size: cover;
        background-image: url(/images/modal-desktop.png?0530c90…);
        left: 0;
        top: 0;
        background-position: right;
        background-repeat: no-repeat;

      }

    }

  }

  &__actions {

    display: flex;
    justify-content: flex-end;

    @include grid-media($grid-medium) {

      margin-bottom: 10px;

    }

  }

  &__social {

    a {

      width: 60px;
      height: 60px;

      &:first-child {

        margin-left: 0;

      }

      svg {

        width: 100%;
        height: 100%;
        fill: map-get($brand, 'button');

      }

    }

  }

  &__information {

    @include grid-media($grid-small) {

      display: flex;
      flex-direction: row;
      justify-content: center;

    }

    @include grid-media($grid-medium) {

      max-width: 960px;
      margin: auto;

    }

    @include grid-media($grid-large) {

      max-width: 100%;
      padding-right: 12%;

    }

    a {

      color: black;

    }

  }

  &__instruction {

    margin-bottom: 30px;

    @include grid-media($grid-small) {

      margin: auto 60px auto 0px;

    }

    button {

      @include grid-media($grid-small) {

        margin-left: 0;

      }

    }

    hr {

      margin: 15px 0;
      opacity: 1;
      border-top: 2px solid map-get($brand, 'hr-modal');

      @include grid-media($grid-medium) {

        display: none;

      }

    }

    p {

      @extend .t-modal__description;

    }

    .c-form__error {

      margin: 20px 0 0 0;

      & + .c-form__error {
        margin-top: 5px;
      }

    }

  }

  &__form {

    button {

      display: block;
      max-width: 300px;
      margin: auto;

    }

  }

  &__close {

    margin: 0;
    width: 40px;
    height: 40px;
    position: relative;

    @include grid-media($grid-small) {

      width: 60px;
      height: 60px;
      padding: 20px;

    }

    &:before,
    &:after {

      content: "";
      position: absolute;
      background-color: white;
      border-radius: 1px;
      height: 2px;
      width: 16px;
      top: 50%;
      left: calc(50% - 8px);
      transform: translate(-50%, -50%);

      @include grid-media($grid-small) {

        border-radius: 1.5px;
        height: 3px;
        width: 23px;
        left: calc(50% - 11.5px);

      }

    }

    &:before {

      transform:
        rotate(-45deg);

    }

    &:after {

      transform:
        rotate(45deg);

    }

  }

  &__title {

    margin: 10px 0 15px 0;
    @extend .t-modal__title;

  }

  .c-card {

    margin: 20px auto 30px auto;
    max-width: 300px;

    @include grid-media($grid-small) {

        min-width: 280px;

    }

    @include grid-media($grid-medium) {

        min-width: 350px;
        max-width: 350px;

    }

  }

  .c-buttons__social {

    display: flex;
    justify-content: center;

    @include grid-media($grid-small) {

      justify-content: center;
      width: 100%;

    }

    @include grid-media($grid-medium) {

      justify-content: center;
      width: auto;
      padding-right: 25px;
      border-right: 3px solid map-get($brand, 'hr-modal');;
      margin-right: 25px;

    }

    a {

      margin: 0 10px;

    }

  }

  .c-buttons {

    @include grid-media($grid-small) {

      display: flex;

    }

  }

  .c-button {

    margin-bottom: 0;
    color: white !important;

  }

  h2,
  p {

    max-width: 470px;

  }

}
