.c-form {

  color: white;
  margin-top: 100px;
  padding: 0 20px;

  @include grid-media($grid-small) {

    padding: 0 40px;

  }

  &__inner {

    display: flex;
    margin: auto;
    justify-content: flex-start;
    max-width: map-get($sizes, "max-width-inner");

  }

  &__container {

    width: 100%;
    color: black;
    background-color: white;
    padding: 20px 20px 40px 20px;

    @include grid-media($grid-small) {

      padding: 45px 55px 60px 55px;

    }

    @include grid-media($grid-medium) {

      padding: 45px 70px 60px 70px;
      width: 100%;
      max-width: 825px;

    }

  }

  &__bold {

    font-weight: 800;

  }

  &__error {
    color: map-get($brand, "error");;
    margin-bottom: 15px;

    input + & {

      margin-top: -5px;

      @include grid-media($grid-small) {

        margin-top: -10px;

      }
    }
  }

  &__notice {
      color: map-get($brand, "accent");
      font-weight: bold;
      margin-top: 25px;
  }

  h3 {

    color: map-get($brand, "accent");

  }

  p {

    @include grid-media($grid-small) {

      line-height: 30px;

    }

  }

  a {

    display: contents;
    margin: 0 3px;

  }

}

label {

  display: none;

}

input[type="text"], input[type="email"] {

  width: 100%;
  margin: 0 0 10px 0;
  display: block;
  border: 2px solid map-get($brand, "input");
  padding: 9px 20px;
  border-radius: 5px;
  @extend .t-form__input;

  @include grid-media($grid-small) {

    padding: 14px 20px;
    margin: 0 0 16px 0;

  }

  &:focus {

    outline: none;
    border-color: map-get($brand, "input-focus");

  }

  &.error {
      border-color: map-get($brand, "error");
  }

}
