.fade-enter-active,
.fade-leave-active {
  transition-duration: $trans-speed;
  transition-property: opacity;
  transition-timing-function: $trans-ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.modal-fade-enter,
.modal-fade-leave-active {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition:
      opacity $trans-speed $trans-ease;
}
