.c-header {

  width: 100%;
  top: 0;
  background-color: transparent;
  position: absolute;
  height: 70px;
  transition:
    position $trans-speed $trans-ease $trans-ease,
    background-color $trans-speed $trans-ease;

  @include grid-media($grid-small) {

    padding: 0 20px;
    height: 140px;

  }

  @include grid-media($grid-medium) {

    padding: 0 40px;

  }

  &__inner {

    max-width: map-get($sizes, 'max-width-inner');
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include grid-media($grid-large) {

      max-width: 100%;
      padding: 0 105px;

    }

  }

  &__controls {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    margin: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: map-get($depth, 'header');
    pointer-events: none !important;
    transition:
      background-color $trans-speed $trans-ease;

    @include grid-media($grid-small) {

      padding: 0 40px;

    }

  }

  &__hamburger {

    position: fixed;
    right: 20px;
    top: 16px;
    transform: translate3d(0px, 0px, 100px);
    z-index: map-get($depth, 'header');
    transform: scale(1.25);

    @include grid-media($grid-small) {

      right: 40px;
      top: 40px;

    }

    @include grid-media($grid-medium) {

      transform: scale(1);

    }

  }

  &__logo {

    width: 199px;
    height: 38px;
    pointer-events: auto;
    transform:
      translate3d(0, 0, 0);
    transition:
      width $trans-speed $trans-ease,
      height $trans-speed $trans-ease;

    @include grid-media($grid-small) {

      width: 454px;
      height: 88px;

    }

    .is-scrolling-down &,
    .has-scrolled-down &,
    .is-scrolling-up.has-scrolled-down & {

      width: 199px;
      height: 38px;

      @include grid-media($grid-small) {

        width: 454px;
        height: 88px;

      }

    }

    svg {

      fill: map-get($brand, "button");
      opacity: 1;
      transition:
        fill $trans-speed $trans-ease,
        opacity $trans-speed $trans-ease;

      .has-navigation & {

        fill: white;
        opacity: 0;

      }

    }

  }

  &__panel {

    position: fixed;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    opacity: 0;
    display: flex;
    z-index: map-get($depth, 'menu');
    transform:
      translate3d(100%, 0, 0);
    transition:
      transform $trans-speed $trans-ease,
      opacity $trans-speed $trans-ease;

    .has-navigation & {
      opacity: 1;
      transform:
        translate3d(0, 0, 0);
      transition:
        transform $trans-speed $trans-ease,
        opacity $trans-speed $trans-ease;
    }

  }

  &__list {

    @include grid-media($grid-small) {

      display: flex;
      justify-content: center;

    }

  }

  &__menu {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    z-index: map-get($depth, 'header');
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: map-get($brand, 'button');
    margin: 0;

    .has-navigation & {

      pointer-events: auto;

    }

    .c-header__controls {

      position: relative;
      height: 70px;
      min-height: 70px;

      @include grid-media($grid-small) {

        height: 150px;
        min-height: 150px;

      }

      svg {

        opacity: 1;

      }

    }

  }

  &__footer {

    width: 100%;
    color: white;
    height: auto;
    padding: 0 20px;
    margin: 10px 0px 20px 0px;

    @include grid-media($grid-small) {

      height: auto;
      margin: 25px 0px 30px 0px;

    }

    hr {

      margin: 0;

    }

  }

  &__button {

    display: block;
    margin-right: 50px;
    background-color: #EF476F;

    @include grid-media($grid-small) {

      margin-right: 70px;

    }

  }

  &__menu_logo {

    fill: white;
    width: 60px;
    height: 60px;

    @include grid-media($grid-small) {

      width: 80px;
      height: 80px;

    }

  }

  &__signature {

    ul {

      margin: 15px 0 0 0;
      padding: 0;
      text-align: center;

      @include grid-media($grid-small) {

        margin: 25px 0 0 0;

      }

      li {

        display: flex;
        justify-content: center;
        @extend .t-menu__signature;

      }

    }

  }

}
