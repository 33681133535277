.c-checkbox {

  display: flex;
  align-items: center;
  position: relative;
  padding-left: 26px;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  min-height: 16px;
  @extend .t-form__checkbox;

  @include grid-media($grid-small) {

    padding-left: 26px;

  }

  &:last-child {

    margin-bottom: 0;

  }

  &:hover input ~ .checkmark {

    background-color: white;

  }

  input {

    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {

      background-color: white;

    }

    &:checked ~ .checkmark:after {

      display: block;

    }

  }

  .checkmark {

    position: absolute;
    top: 0;
    left: 0;
    top: 6px;
    height: 16px;
    width: 16px;
    border-radius: 10px;
    border: 1px solid map-get($brand, "button");

    @include grid-media($grid-small) {

      width: 16px;

    }

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

  }

  &__small {

    @extend .t-form__small;

  }

  .checkmark:after {

    left: calc(50%);
    top: 50%;
    width: 10px;
    height: 10px;
    background: map-get($brand, "button");;
    border-radius: 10px;
    transform: translate(-50%, -50%);

  }

}
