.c-nav-primary {

  width: 100%;
  height: calc(100% - 70px - 71px);
  flex-direction: column;
  display: flex;
  justify-content: center;

  @include grid-media($grid-small) {

    min-height: auto;
    height: auto;

  }

  &__list,
  .sub-menu {

    @extend %list-reset;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    li {

      &:first-child {

        @include grid-media($grid-medium) {

          margin-top: 40px;

        }

      }

    }

  }

  li {

    white-space: nowrap;
    width: 100%;
    text-align: center;
    margin: 0 0 24px 0;

    &:last-child {

      margin-bottom: 0;

    }

    &.current-menu-item {

      a {

        font-weight: 800;
        color: map-get($brand, 'accent-pink');

      }

    }

  }

  a {

    color: white;
    font-weight: 500;
    padding: 0 0 3px 0;
    text-decoration: none;
    display: inline-flex;
    align-items: baseline;
    cursor: pointer;

    &.current-page-item {

      border-bottom: 2px solid white;

    }

  }

  &__title {

    @extend .t-menu__primary;

  }

  svg {

    width: 18px;
    height: 18px;
    fill: white;
    margin-left: 10px;
    display: block;

    @include grid-media($grid-small) {

      width: 30px;
      height: 30px;

    }

  }

}
