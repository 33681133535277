.c-hero {

  &__inner {

    width: 100%;
    position: relative;

  }

  &__background {

    position: relative !important;

  }

  img {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    mix-blend-mode: multiply;

    .ios & {

      mix-blend-mode: darken;

    }

  }

  svg {

    width: 100%;
    height: auto;

  }

}
