.c-video {
  position: relative;

  > .c-video__height {
    padding-top: 60%;
    display: block;
  }
}

.c-videos {

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
  background-color: map-get($brand, 'footer');
  padding-bottom: 10px;

  @include grid-media($grid-x-small) {

    padding: 15px;

  }

  @include grid-media($grid-medium) {

    padding: 30px;

  }

  &__inner {

    margin: auto;
    width: 100%;
    max-width: map-get($sizes, "max-width-inner");
    padding-top: 20px;

    @include grid-media($grid-x-small) {

      display: flex;
      justify-content: space-between;
      padding-top: 40px;

      // ensure correct grid alignment for partial rows
      &::after {

        content: "";
        flex: auto;

      }

    }

  }

  &__grass {

    svg {

      fill: map-get($brand, 'footer');

    }

  }

  .c-video {

    width: 100%;
    margin-bottom: 20px;

    @include grid-media($grid-medium) {

      width: calc(50% - 20px);
      margin-bottom: 40px;

    }

  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

}
