.c-card {

  background-color: map-get($brand, 'card');
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 40px;
  box-shadow: 0px 0px 20px 0px map-get($brand, 'drop-shadow');
  transition:
    box-shadow ($trans-speed * 2) $trans-ease;

  @include grid-media($grid-small) {

    margin-bottom: 60px;

  }

  &:hover {

    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.2);

  }

  &__link {

    display: block;
    text-decoration: none;

  }

  &__media {

    position: relative;
    padding-top: 100%;
    background-position: center;
    background-size: cover;

    svg {

      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      fill: map-get($brand, 'card');
      z-index: 2;

    }

  }

  &__loading {

    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;

  }

  &__content {

    padding: 10px 15px 20px 15px;

  }

  &__title {

    margin: 0 0 5px 0;
    @extend .t-card__title;

  }

  &__description {

    margin: 5px 0 0 0;
    @extend .t-card__description;

  }

  &__upload {
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 100%;

    input[type=file] {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 2;
        opacity: 0;
        min-height: 300px;
    }

    a {
        display: block;
        position: relative;
        z-index: 1;
        margin: 0;
        width: 100%;
        height: 300px;
        background: url('/images/card-placeholder.png') no-repeat center/cover;
        text-indent: -1000em;
        overflow: hidden;
    }
  }

  input,
  input[type="text"],
  textarea {

    width: 100%;
    padding: 6px;
    color: white;
    outline: none;
    border: 1px dashed map-get($brand, 'card');
    background-color: transparent;
    resize: none;

    &:focus,
    &.empty {

      border: 1px dashed map-get($brand, 'card-input');

    }

  }

  input,
  input[type="text"] {

    @extend .t-card__title;

  }

  textarea {

    @extend .t-card__description;
    min-height: 80px;

  }

  ::-webkit-input-placeholder {
    color: map-get($brand, 'card-input');
  }

  :-ms-input-placeholder {
    color: map-get($brand, 'card-input');
  }

  ::placeholder {
    color: map-get($brand, 'card-input');
  }

}

.c-cards {

  padding: 0 20px;
  background-color: map-get($brand, 'footer');
  padding-bottom: 10px;

  @include grid-media($grid-x-small) {

    padding: 0 15px;

  }

  @include grid-media($grid-medium) {

    padding: 0 30px;

  }

  &__inner {

    margin: auto;
    width: 100%;
    max-width: map-get($sizes, "max-width-inner");
    padding-top: 20px;

    @include grid-media($grid-x-small) {

      display: flex;
      justify-content: space-between;
      padding-top: 40px;

      // ensure correct grid alignment for partial rows
      &::after {

        content: "";
        flex: auto;

      }

    }

  }

  &__grass {

    margin-bottom: -2px;

    svg {

      fill: map-get($brand, 'footer');

    }

  }

  &--4up {

    flex-wrap: wrap;

    .c-card {

      width: 100%;

      @include grid-media($grid-x-small) {

        width: calc(50% - 20px);
        margin-left: 10px;
        margin-right: 10px;

      }

      @include grid-media($grid-medium) {

        width: calc(25% - 20px);

      }

    }

  }

  &--1down,
  &--2down,
  &--3down {

    &:after {

      @include grid-media($grid-medium) {

        content: "";
        height: 1px;
        background-color: transparent;

      }

    }

  }

  &--1down {

    &:after {

      @include grid-media($grid-medium) {

        width: calc((25% * 3) - 15px);

      }

    }

  }

  &--2down {

    &:after {

      @include grid-media($grid-medium) {

        width: calc((25% * 2) - 15px);

      }

    }

  }

  &--3down {

    &:after {

      @include grid-media($grid-medium) {

        width: calc(25% - 15px);

      }

    }

  }
}
