$bar-width: 14px;
$bar-spacing: 5px;
$bar-height: 2px;

$bar-width-before: 14px;
$bar-width-middle: 10px;
$bar-width-after: 14px;

$bar-spacing-desktop: 7px;
$bar-height-desktop: 3px;

$bar-width-before-desktop: 20px;
$bar-width-middle-desktop: 15px;
$bar-width-after-desktop: 20px;

.c-hamburger {

  $root: &;

  display: block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: map-get($brand, "button");
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px map-get($brand, 'drop-shadow');
  transition:
    background-color $trans-speed $trans-ease;

    @include grid-media($grid-small) {

      width: 60px;
      height: 60px;

    }

  .has-navigation & {

    background-color: white;

  }

  &:hover {

    #{$root}__patty {

      opacity: 0.3;

    }

  }

  &__patty {

    position: absolute;
    top: calc(50% - 17px);
    left: calc(50% - 8px);
    transform: translate(50%, calc(16px));
    background-color: white;
    width: $bar-width-middle;
    height: $bar-height;
    border-radius: $bar-height;
    transition:
      opacity $trans-speed $trans-ease;

    @include grid-media($grid-small) {

      width: $bar-width-middle-desktop;
      height: $bar-height-desktop;
      border-radius: 1.5px;
      left: calc(50% - 13px);

    }

    .has-navigation & {

      background-color: transparent;

    }

    &::after,
    &::before {

      background-color: white;
      height: $bar-height;
      content: "";
      position: absolute;
      border-radius: $bar-height;
      transition:
        background-color ($trans-speed / 2) $trans-ease,
        top ($trans-speed / 2) $trans-ease ($trans-delay / 2),
        bottom ($trans-speed / 2) $trans-ease ($trans-delay / 2),
        transform ($trans-speed / 2) $trans-ease;

      @include grid-media($grid-small) {

        height: $bar-height-desktop;

      }

      .has-navigation & {

        background-color: map-get($brand, "button");
        transition:
          background-color ($trans-speed / 2) $trans-ease,
          top ($trans-speed / 2) $trans-ease,
          bottom ($trans-speed / 2) $trans-ease,
          transform ($trans-speed / 2) $trans-ease ($trans-delay / 2);


      }

    }

    &:before {

      right: 0;
      width: $bar-width-before;
      bottom: $bar-spacing;

      @include grid-media($grid-small) {

        width: $bar-width-before-desktop;
        height: $bar-height-desktop;
        bottom: $bar-spacing-desktop;
        border-radius: 1.5px;

      }

      .has-navigation & {

        bottom: 0;
        transform:
          rotate(45deg);

      }

    }

    &:after {

      right: 0;
      width: $bar-width-after;
      top: $bar-spacing;

      @include grid-media($grid-small) {

        width: $bar-width-after-desktop;
        height: $bar-height-desktop;
        top: $bar-spacing-desktop;
        border-radius: 1.5px;

      }

      .has-navigation & {

        top: 0;
        transform:
          rotate(-45deg);

      }

    }

  }

}
