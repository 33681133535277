@font-face {
  font-family: 'Welcome 2019';
      src: url('/fonts/Welcome-2019.otf') format('opentype'),
           url('/fonts/Welcome-2019.woff2') format('woff2'),
           url('/fonts/Welcome-2019.woff') format('woff'),
           url("/fonts/Welcome-2019.tff") format('truetype');
      font-weight: 800;
      font-style: normal;
      font-display: swap;
}
