h1,
h2,
h3,
h4,
h5,
h6 {

  font-weight: 400;
  margin: 20px 0;

}

.t-h2,
h2 {

  font-size: 48px;
  line-height: 48px;
  font-weight: 800;

  @include grid-media($grid-medium) {

    font-size: 120px;
    line-height: 120px;

  }

}

.t-h3,
h3 {

  font-family: 'Welcome 2019';
  font-size: 34px;
  line-height: 40px;

  @include grid-media($grid-small) {

    font-size: 90px;
    line-height: 90px;

  }

}

a {

  color: map-get($brand, "button");
  text-decoration: underline;
  font-weight: 800;

}

p {

  color: inherit;
  font-size: 16px;
  line-height: 24px;

  @include grid-media($grid-small) {

    font-size: 22px;

  }

}

.t-header {

  font-size: 14px;
  color: white;

  @include grid-media($grid-small) {

    font-size: 18px;

  }

}

.t-bold {

  font-weight: 600;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -3px;

  @include grid-media($grid-small) {

    font-size: 120px;
    line-height: 120px;
    letter-spacing: -3px;

  }

  @include grid-media($grid-medium) {

    font-size: 120px;
    line-height: 130px;
    letter-spacing: -4px;
    max-width: 832px !important;

  }

  @include grid-media($grid-large) {

    font-size: 180px;
    line-height: 170px;
    letter-spacing: -4px;
    max-width: 100% !important;

  }

  &__detail {

    font-size: 28px;
    line-height: 30px;
    font-weight: 500;

    @include grid-media($grid-small) {

      font-size: 45px;
      line-height: 48px;

    }

    @include grid-media($grid-medium) {

      font-size: 60px;
      line-height: 65px;

    }

  }

}

.t-overview {

  font-size: 18px;
  color: map-get($brand, "accent");
  font-weight: 600;

  @include grid-media($grid-small) {

    font-size: 22px;

  }

}

.t-form {
  &__header {

    color: white;
    font-size: 18px;
    line-height: 20px;

    @include grid-media($grid-small) {

      font-size: 20px;
      line-height: 24px;

    }

  }

  &__input {

    font-size: 14px;
    line-height: 28px;
    color: black;
    font-weight: 400;

    @include grid-media($grid-small) {

      font-size: 18px;
      line-height: 28px;

    }

  }

  &__checkbox {

    font-size: 16px;

    @include grid-media($grid-small) {

      font-size: 18px;

    }

  }

  &__small {

    font-size: 13px;

    @include grid-media($grid-small) {

      font-size: 15px;

    }

  }

}

.t-detail {

  font-size: 14px;

}

.t-footer {
  &__signature {

    font-size: 12px;
    line-height: 18px;

  }

  &__title {

    @include grid-media($grid-small) {

      font-size: 105px;
      line-height: 100px;

    }

  }

}

.t-button {

  font-size: 22px;
  line-height: 20px;
  font-weight: 800;

}

.t-menu {
  &__primary {

    font-size: 26px;
    line-height: 30px;

    @include grid-media($grid-small) {

      font-size: 40px;
      line-height: 50px;

    }

  }

  &__signature {

    font-size: 12px;
    line-height: 18px;

  }

}

.t-garden {
  &__title {

    font-size: 60px;
    line-height: 60px;
    font-weight: 600;
    letter-spacing: -3px;

    @include grid-media($grid-small) {

      font-size: 80px;
      line-height: 80px;

    }

    @include grid-media($grid-medium) {

      font-size: 90px;
      line-height: 90px;

    }

    @include grid-media($grid-medium) {

      font-size: 120px;
      line-height: 120px;
      letter-spacing: -3.6px;

    }

  }

  &__share {

    @include grid-media($grid-medium) {

      font-size: 70px;
      line-height: 76px;

    }

  }

}

.t-card {
  &__description,
  &__title {

    font-family: $sans-serif;
    color: white;

  }

  &__title {

    font-weight: 500;
    font-size: 20px;
    line-height: 28px;

  }

  &__description {

    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

  }

}

.t-modal {
  &__title {

    font-weight: 500;
    font-size: 25px;
    line-height: 32px;

    @include grid-media($grid-small) {

      font-size: 32px;
      line-height: 40px;

    }

    @include grid-media($grid-medium) {

      font-size: 50px;
      line-height: 60px;

    }

  }

  &__description {

    font-size: 16px;
    line-height: 22px;

    @include grid-media($grid-small) {

      font-size: 20px;
      line-height: 26px;

    }

    @include grid-media($grid-large) {

      font-size: 22px;
      line-height: 28px;

    }

  }

}

.t-cookie {
  &__title {

    margin-top: 0;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 28px !important;

  }

  &__description {

    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 26px !important;

  }

  &__button {

    font-size: 18px !important;
    line-height: 20px !important;

  }

}
