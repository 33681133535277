.c-section {

  position: relative;

  &--2 {

    margin: 60px 0 261px 0;

    @include grid-media($grid-small) {

      margin: 177px 0 292px 0;

    }

  }

  &--3 {

    margin: calc((100vw * 0.54) + 40px) 0 85px 0;

    @include grid-media($grid-small) {

      margin: 292px 0 150px 0;

    }

    @include grid-media($grid-large) {

      margin: 292px 0 327px 0;

    }

  }

  &--4 {

    margin: 85px 0 137px 0;

    @include grid-media($grid-small) {

      margin: 150px 0 85px 0;

    }

    @include grid-media($grid-large) {

      margin: 327px 0 85px 0;

    }

  }

  &--5 {

    margin: 137px 0 177px 0;

    @include grid-media($grid-small) {

      margin: 230px 0 190px 0;

    }

  }

  &--garden {

    margin: 90px 0 50px 0;

    @include grid-media($grid-small) {

      margin: 180px 0 50px 0;

    }

  }

  &--create {

    margin: 90px 0 0px 0;

    @include grid-media($grid-small) {

      margin: 30px 0 0px 0;

    }

  }

  &--modal {

    z-index: map-get($depth, "modal");

  }

}
