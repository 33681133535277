.c-branch {
  position: relative;

  &:after,
  &:before {
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
  }

  &.c-form {
    &:before {
      content: "";
      background-image: url("images/branch/branch-mobile-2-before.png");
      width: 164px;
      height: 184px;
      bottom: 170px;
      left: -70px;

      @include grid-media($grid-small) {

        background-image: url("images/branch/branch-desktop-2-before.png");
        width: 35vw;
        height: 35vw;

      }
    }

    &:after {
      content: "";
      background-image: url("images/branch/branch-mobile-2-after.png");
      width: calc(100vw * 0.52);
      height: calc(100vw * 0.52 * 0.9);
      top: calc(100vw * -0.42);
      right: calc(100vw * -0.1);

      @include grid-media($grid-small) {

        background-image: url("images/branch/branch-desktop-2-after.png");
        height: calc(100vw * 0.52 * 1.2);
        right: 0;

      }
    }
  }

  &.c-detail#rose {
    margin-top: calc(100vw * 0.64);

    @include grid-media($grid-medium) {

      margin-top: 0;

    }

    &:before {
      content: "";
      background-image: url("images/branch/branch-mobile-3-before.png");
      width: 108vw;
      height: calc(100vw * 0.58);
      top: calc((100vw * -0.58) - 20px);
      left: 20px;

      @include grid-media($grid-small) {

        background-image: url("images/branch/branch-tablet-3-before.png");
        width: calc(100vw * 0.68);
        height: 108vw;
        transform: rotate(-52deg);
        left: unset;
        right: 8vw;
        top: calc(100vw * -0.74);

      }

      @include grid-media($grid-medium) {

        background-image: url("images/branch/branch-desktop-3-before.png");
        width: calc(100vw * 0.29);
        height: 53vw;
        transform: rotate(-52deg);
        left: unset;
        right: 0;
        top: calc(100vw * -0.5);
        transform: rotate(0deg);

      }
    }

    &:after {

      @include grid-media($grid-medium) {

        content: "";
        background-image: url("images/branch/branch-desktop-3-after.png");
        width: 34.5vw;
        height: calc(37vw + 20px);
        left: 0;
        top: calc(100vw * 0.02);

      }

    }

  }

  &.c-bold {
    &:before {
      content: "";
      background-image: url("images/branch/branch-mobile-4-before.png");
      width: calc(100vw * 0.38);
      height: calc(100vw * 0.5);
      bottom: 0;
      left: calc(100vw * -0.35);

      @include grid-media($grid-small) {

        display: none;

      }

    }

    &:after {
      content: "";
      background-image: url("images/branch/branch-mobile-4-after.png");
      width: calc(100vw * 0.35);
      height: calc(100vw * 0.35 * 2);
      top: calc(100vw * -0.1);
      right: calc(100vw * -0.08);

      @include grid-media($grid-medium) {

        background-image: url("images/branch/branch-desktop-4-after.png");
        right: 0;
        top: calc(100vw * 0.14);

      }

      @include grid-media($grid-large) {

        top: calc(100vw * 0.08);

      }
    }
  }

  &.c-detail#action {
    margin-top: calc((100vw * 0.34) - 20px);

    @include grid-media($grid-medium) {

      margin-top: 0;

    }

    &:before {
      content: "";
      background-image: url("images/branch/branch-mobile-5-before.png");
      width: 70vw;
      height: calc(100vw * 0.28);
      top: calc(100vw * -0.3);
      left: -10px;

      @include grid-media($grid-small) {

        background-image: url("images/branch/branch-tablet-5-before.png");
        height: calc(100vw * 0.28);
        top: calc(100vw * -0.28);
        left: -10px;
        transform: rotate(-5deg);

      }

      @include grid-media($grid-medium) {

        background-image: url("images/branch/branch-desktop-5-before.png");
        transform: rotate(0);
        right: 0;
        left: unset;
        top: -50vw;
        height: 45vw;
        width: 33vw;

      }

    }

  }

  &.c-footer {
    margin-top: calc((100vw * 0.44) - 20px);

    @include grid-media($grid-small) {

      margin-top: calc((100vw * 0.34) + 20px);

    }

    @include grid-media($grid-medium) {

      margin-top: 0;

    }

    &:before {
      content: "";
      background-image: url("images/branch/branch-mobile-footer-before.png");
      width: 100vw;
      height: calc(100vw * 0.48);
      top: calc((100vw * -0.32) - 20px);
      right: -18vw;

      @include grid-media($grid-small) {

        width: 64vw;
        height: 26vw;
        top: -26vw;
        right: -14vw;

      }

      @include grid-media($grid-medium) {

        background-image: url("images/branch/branch-desktop-footer-before.png");
        right: unset;
        left: 0;
        width: 34vw;
        height: 63vw;
        top: -48vw;

      }

    }

    .c-footer {

      &__top {

        @include grid-media($grid-small) {

          margin-top: 60px;

        }

        @include grid-media($grid-small) {

          margin-top: 100px;

        }

        img {

          height: calc(100vw - 40px);
          margin-top: calc(100vw * -0.2);

          @include grid-media($grid-small) {

            height: 70vw;
            margin-top: -16vw;
            margin-left: 14vw;

          }

          @include grid-media($grid-medium) {

            width: calc(100vw - 600px);
            margin-top: 0;
            margin-left: 0;
            position: absolute;
            right: 0;
            height: auto;
            top: 240px;

          }

          @include grid-media($grid-large) {

            top: 200px;
            max-width: 768px;

          }

        }

      }

    }

  }

}
