.Cookie,
.c-cookie {

  background-color: white !important;

  @include grid-media($grid-small) {

    left: unset !important;
    right: 20px !important;
    bottom: 20px !important;
    max-width: 350px !important;
    padding: 30px !important;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1) !important;

  }

  &__title {

    @extend .t-cookie__title;
    margin-bottom: 12px;

  }

  &__description {

    margin-top: 12px !important;
    @extend .t-cookie__description;

  }

  button {

    margin-left: 0;
    padding: 15px 20px;
    @extend .t-cookie__button;

  }

}
